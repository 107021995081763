
    import { defineComponent, ref, toRefs, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import moment from "moment";
    import ApproveConfirmModal from "../components/ApproveConfirmModal.vue";
    import ApproveSteps from "@/views/Approve/components/ApproveSteps.vue";
    import { ApproveProgressResponse, PackageDetailResponse } from './types';
    import { approvePackage, getApproveProgress, getMgPackageDetail, getCurrentUserApprovalStatus } from '@/API/approve';
    import blobDownload from "@/utils/blobDownload";
    import { PackageApproveRequestBodyItem,  } from "@/views/Approve/MgRoleApprove/PackageHome/types";
    import useFetch from "@/hooks/useFetch";
    import useTableRowHighlight from "@/hooks/approve/useTableRowHighlight";
    import ProgramPreview from "@/views/Approve/components/ProgramPreview.vue";
    import { ProgramApproveProps } from "@/views/Approve/ControllingRoleApprove/ProgramHome/types";
    import { toThousands } from "@/utils/approve";
    import { useRouter } from "vue-router";
    import WordingBudget from '@/views/Program/Wording/WordingBudget.vue'
    const columns = [
        {
            title: 'No.',
            key: 'no',
            slots: { customRender: 'no' },
            width: 40
        },
        {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
            width: 130,
            ellipsis: true
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'programName',
            slots: { customRender: 'programName' },
            width: 240,
            ellipsis: true
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            slots: { customRender: 'from' },
            width: 90
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            slots: { customRender: 'to' },
            width: 90
        },
        {
            title: 'PbP Budget',
            dataIndex: 'pbpBudget',
            key: 'pbpBudget',
            slots: { customRender: 'pbpBudget' },
            align: 'right'
        },
        {
            title: 'CBU Budget',
            dataIndex: 'cbuBudget',
            key: 'cbuBudget',
            slots: { customRender: 'cbuBudget' },
            align: 'right'
        },
        {
            title: 'VAN Budget',
            dataIndex: 'vanBudget',
            key: 'vanBudget',
            slots: { customRender: 'vanBudget' },
            align: 'right'
        },
        {
            title: 'Total Budget',
            dataIndex: 'totalBudget',
            key: 'totalBudget',
            slots: { customRender: 'totalBudget' },
            align: 'right'
        }
    ];
    export default defineComponent({
        name: "programMgtBatchApprove",
        props: {
            id: String,
            tag: String
        },
        components: { ApproveConfirmModal, ApproveSteps, ProgramPreview, WordingBudget },
        setup(props) {
            const { state } = useStore();
            const router = useRouter();
            //confirm 弹窗
            const visible = ref(false);
            //wording
            const wordingVisible = ref<boolean>(false);
            const programPreviewData = reactive<{ programId: string; programPeriod: string}>({
                programId: '',
                programPeriod: ''
            });
            //approveComment
            const approveComment = ref<string>('');
            //status
            const approveStatus = ref<string>('');
            //batchStatus
            const batchStatus = ref<boolean>();
            //ApproveProgress
            const approveProgressData = reactive<ApproveProgressResponse>({
                id: '',
                buName: '',
                batchId: '',
                totalNodes: 0,
                approvaledNodes: '',
                status: '',
                nodeList: []
            });
            const initData: PackageDetailResponse  = {
                id: '',
                batchId: '',
                batchName: '',
                buName: '',
                status: '',
                nodes: 0,
                pbpBudget: '',
                cbuBudget: '',
                totalBudget: '',
                packageSaveActorId: '',
                packageSaveTime: '',
                packageSubbmitActorId: '',
                packageSubmitTime: '',
                programDtos: []
            }
            const { data: packageDetail } = useFetch<PackageDetailResponse>({ batchId: props.id }, initData, getMgPackageDetail);
            const { currentClickRowIndex, handlerRowClick } = useTableRowHighlight(wordingVisible);
            const handlerApprove = (status: string): void => {
                approveStatus.value = status;
                visible.value = !visible.value;
            }
            const getBatchStatus = () => {
                getCurrentUserApprovalStatus(props.id as string).then(res => {
                    batchStatus.value = res;
                })
            }
            const handlerDownload = (): void => {
                blobDownload({url: '/pgapi/program/wording/preview_approval_batch_pdf', params: { "batchId": props.id }}).then(data => {
                    console.log(data);
                })
            }
            const getPackageApproveProgress = () => {
                getApproveProgress({params: { batchId: props.id as string }}).then(data => {
                    Object.assign(approveProgressData, data);
                })
            }
            // woridng preview
            const visiblePreview = ref(false)
            const handlerClickProgramName = (record: ProgramApproveProps, index: number): void => {
                // wordingVisible.value = true;
                programPreviewData.programId = record.programId;
                programPreviewData.programPeriod = `${moment(record.periodFrom).format('YYYY.MM')}-${moment(record.to).format('YYYY.MM')}`;
                // router.push({
                //   path: "/wordingbudget",
                //   query: {
                //     programId: programPreviewData.programId,
                //     programPeriod: programPreviewData.programPeriod
                //   }
                // });
                const params = {
                    programId: programPreviewData.programId,
                    programPeriod: programPreviewData.programPeriod,
                    programName: `${record.programName}(${record.programCode})`
                }
                // sessionStorage.setItem('paramsWording', JSON.stringify(params))
                // window.open(`${window.location.origin}/#/wordingbudget`)
                sessionStorage.setItem('previewPgmId', record.programId)
                visiblePreview.value = true
                handlerRowClick(index);
            }
            const handlerOk = (): void => {
                const requestBody: PackageApproveRequestBodyItem[] = [{
                    batchId: props.id as string,
                    approvalSatus: approveStatus.value,
                    content: approveComment.value
                }];
                approvePackage(requestBody).then(data => {
                    visible.value = false;
                    //审批或者拒绝重新获取当前的神奇状态和审批流程
                    getBatchStatus();
                    getPackageApproveProgress();
                })
            }
            onMounted(() => {
                getBatchStatus();
                getPackageApproveProgress();
            })
            return {
                //table
                columns,
                //审批
                visible,
                approveComment,
                approveStatus,
                //事件处理
                handlerApprove,
                handlerOk,
                handlerDownload,
                handlerClickProgramName,
                //package approve progress
                ...toRefs(approveProgressData),
                packageDetail,
                //moment
                moment,
                //store
                state,
                //wording
                wordingVisible,
                programPreviewData,
                //千分数转换
                toThousands,
                //当前登录用户的审批状态
                batchStatus,
                //高亮table row
                currentClickRowIndex,
                visiblePreview
            }
        }
    })
